// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-title a {
    transition: all .1s ease;
    font-family: "Playfair Display", serif;
    color:black;
    font-size: 32px;
}
.card{
    margin-bottom: 20px;
}
.card-title a:hover{
    color:orange;
    text-decoration: underline!important;
}
.card-body .card-text1 {
    color: #808080d7;
    font-weight: 300;
    font-size: 17px;
}
.card-body .ad-info span {
    color: orange;
}
.pagination .page-link{
    background-color: #cccccc75;
    color:black;
    box-shadow:none;
    border:none;
}
.pagination .page-link:hover{
    background-color: #183838;
    color:white;
}
.pagination .page-item button{
    border:1px solid #183838;
    margin-right:5px;
}
.react-multi-carousel-dot-list{
    background-color:#cccccc;
}

@media(max-width:768px){
    .card-title a {
        font-size: 28px;}
}
@media(max-width:600px){
    .card-title a {
        font-size: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Home/Blog.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,sCAAsC;IACtC,WAAW;IACX,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,oCAAoC;AACxC;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,2BAA2B;IAC3B,WAAW;IACX,eAAe;IACf,WAAW;AACf;AACA;IACI,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,wBAAwB;IACxB,gBAAgB;AACpB;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,eAAe,CAAC;AACxB;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".card-title a {\n    transition: all .1s ease;\n    font-family: \"Playfair Display\", serif;\n    color:black;\n    font-size: 32px;\n}\n.card{\n    margin-bottom: 20px;\n}\n.card-title a:hover{\n    color:orange;\n    text-decoration: underline!important;\n}\n.card-body .card-text1 {\n    color: #808080d7;\n    font-weight: 300;\n    font-size: 17px;\n}\n.card-body .ad-info span {\n    color: orange;\n}\n.pagination .page-link{\n    background-color: #cccccc75;\n    color:black;\n    box-shadow:none;\n    border:none;\n}\n.pagination .page-link:hover{\n    background-color: #183838;\n    color:white;\n}\n.pagination .page-item button{\n    border:1px solid #183838;\n    margin-right:5px;\n}\n.react-multi-carousel-dot-list{\n    background-color:#cccccc;\n}\n\n@media(max-width:768px){\n    .card-title a {\n        font-size: 28px;}\n}\n@media(max-width:600px){\n    .card-title a {\n        font-size: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
