// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-slider::before,
.icon-slider::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.icon-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.icon-slider::before {
  left: 0;
  top: 0;
}

@keyframes icon-scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-250px * 7)); }
}

.icon-slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.icon-slide-track {
  animation: icon-scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.icon-slide {
  height: 100px;
  width: 220px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Iconslider/Iconslider.css"],"names":[],"mappings":"AAAA;;EAEE,6FAA6F;EAC7F,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,QAAQ;EACR,MAAM;EACN,0BAA0B;AAC5B;;AAEA;EACE,OAAO;EACP,MAAM;AACR;;AAEA;EACE,KAAK,wBAAwB,EAAE;EAC/B,OAAO,uCAAuC,EAAE;AAClD;;AAEA;EACE,iBAAiB;EACjB,gDAAgD;EAChD,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;EAC1C,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":[".icon-slider::before,\n.icon-slider::after {\n  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);\n  content: \"\";\n  height: 100px;\n  position: absolute;\n  width: 200px;\n  z-index: 2;\n}\n\n.icon-slider::after {\n  right: 0;\n  top: 0;\n  transform: rotateZ(180deg);\n}\n\n.icon-slider::before {\n  left: 0;\n  top: 0;\n}\n\n@keyframes icon-scroll {\n  0% { transform: translateX(0); }\n  100% { transform: translateX(calc(-250px * 7)); }\n}\n\n.icon-slider {\n  background: white;\n  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);\n  height: 100px;\n  margin: auto;\n  overflow: hidden;\n  position: relative;\n}\n\n.icon-slide-track {\n  animation: icon-scroll 40s linear infinite;\n  display: flex;\n  width: calc(250px * 14);\n}\n\n.icon-slide {\n  height: 100px;\n  width: 220px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
