// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downborder {
    text-align: center;
}
.downborder img {
    width: 35%;
    transform: scaleY(-1);
}
@media(max-width:600px){
    .downborder img {
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Editorial/Downborder.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,qBAAqB;AACzB;AACA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".downborder {\n    text-align: center;\n}\n.downborder img {\n    width: 35%;\n    transform: scaleY(-1);\n}\n@media(max-width:600px){\n    .downborder img {\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
