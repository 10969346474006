// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guidediv {
    width: 75%;
    display: block;
    margin: 20px auto;
    /* background-color: #dcdcdc7d; */
    padding: 30px 40px;
}
    .guidediv ul h5 {
        text-decoration: underline;
        font-size: 29px;
    font-style: italic;
    font-family: "Lora", serif;
    font-weight: bolder;
    color: #32757e;
    }
    .guidediv ul li{
        list-style: square;
        color: #32757e;
    }

        .guidediv p {
            color: rgb(43, 41, 41);
            font-size: 17px;
        }
    `, "",{"version":3,"sources":["webpack://./src/Components/Guideline/Guideline.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,iCAAiC;IACjC,kBAAkB;AACtB;IACI;QACI,0BAA0B;QAC1B,eAAe;IACnB,kBAAkB;IAClB,0BAA0B;IAC1B,mBAAmB;IACnB,cAAc;IACd;IACA;QACI,kBAAkB;QAClB,cAAc;IAClB;;QAEI;YACI,sBAAsB;YACtB,eAAe;QACnB","sourcesContent":[".guidediv {\n    width: 75%;\n    display: block;\n    margin: 20px auto;\n    /* background-color: #dcdcdc7d; */\n    padding: 30px 40px;\n}\n    .guidediv ul h5 {\n        text-decoration: underline;\n        font-size: 29px;\n    font-style: italic;\n    font-family: \"Lora\", serif;\n    font-weight: bolder;\n    color: #32757e;\n    }\n    .guidediv ul li{\n        list-style: square;\n        color: #32757e;\n    }\n\n        .guidediv p {\n            color: rgb(43, 41, 41);\n            font-size: 17px;\n        }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
