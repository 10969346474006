// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whitecardd{
    width:90%;
    text-align: center;
    margin: auto;
}
.whitecardd .card {
    margin: 15px 0;
    padding: 34px 25px;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 15px;
    cursor: pointer;
    height: 248px;
    border: 1px solid #f5eb2f96;
    display: flex;
    align-items: center;
    justify-content: center;
}
.whitecardd .card h3 {
    color: #06461b;
    padding-bottom: 20px;
}
.whitecardd .card:hover {
    background-color: #43b75524;
    transform: translateY(-15px);
}
@media(max-width:768px){
    .whitecardd .card{
        height:300px;
    }
}
@media(max-width:600px){
    .whitecardd .card{
        height:auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Guideline/Whitecard.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,iDAAiD;IACjD,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,cAAc;IACd,oBAAoB;AACxB;AACA;IACI,2BAA2B;IAC3B,4BAA4B;AAChC;AACA;IACI;QACI,YAAY;IAChB;AACJ;AACA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".whitecardd{\n    width:90%;\n    text-align: center;\n    margin: auto;\n}\n.whitecardd .card {\n    margin: 15px 0;\n    padding: 34px 25px;\n    transition: background-color 0.3s, transform 0.3s;\n    border-radius: 15px;\n    cursor: pointer;\n    height: 248px;\n    border: 1px solid #f5eb2f96;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.whitecardd .card h3 {\n    color: #06461b;\n    padding-bottom: 20px;\n}\n.whitecardd .card:hover {\n    background-color: #43b75524;\n    transform: translateY(-15px);\n}\n@media(max-width:768px){\n    .whitecardd .card{\n        height:300px;\n    }\n}\n@media(max-width:600px){\n    .whitecardd .card{\n        height:auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
