// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    padding:0;
    margin:0;
    box-sizing: border-box;
}
body{
    background-image: url('https://ecroptoday.azureedge.net/ecroptoday/Leaf.webp');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
a{
    text-decoration: none!important;
}
.container{
    max-width: 1497px!important;
 padding:10px!important;
 background-color: #cccccc4d;

}
.outer-container{
    padding:50px 0 0;
}
@media (max-width:768px){
    .container{
        padding:20px!important;
        max-width: 95% !important;
    } 
}
@media(max-width:600px){
    .outer-container{
        padding:50px 0 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,QAAQ;IACR,sBAAsB;AAC1B;AACA;IACI,8EAA8E;IAC9E,4BAA4B;IAC5B,4BAA4B;IAC5B,sBAAsB;AAC1B;AACA;IACI,+BAA+B;AACnC;AACA;IACI,2BAA2B;CAC9B,sBAAsB;CACtB,2BAA2B;;AAE5B;AACA;IACI,gBAAgB;AACpB;AACA;IACI;QACI,sBAAsB;QACtB,yBAAyB;IAC7B;AACJ;AACA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["*{\n    padding:0;\n    margin:0;\n    box-sizing: border-box;\n}\nbody{\n    background-image: url('https://ecroptoday.azureedge.net/ecroptoday/Leaf.webp');\n    background-attachment: fixed;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\na{\n    text-decoration: none!important;\n}\n.container{\n    max-width: 1497px!important;\n padding:10px!important;\n background-color: #cccccc4d;\n\n}\n.outer-container{\n    padding:50px 0 0;\n}\n@media (max-width:768px){\n    .container{\n        padding:20px!important;\n        max-width: 95% !important;\n    } \n}\n@media(max-width:600px){\n    .outer-container{\n        padding:50px 0 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
