// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style for the title holder to align items in a row */
.title-holder {
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Center items vertically */
    gap: 10px; /* Space between text and animated border */
    padding: 20px;
    width: 100%; /* Ensure it takes up full width */
    box-sizing: border-box; /* Include padding in element's total width */
}

/* Style for the text */
.text {
    font-size: 2rem;
    color: green;
    margin: 0;
    white-space: nowrap;
    font-weight: 700;
}

/* Style for the animated border */
.animate-border {
    background-color: #ddd;
    height: 1px;
    position: relative;
    flex-grow: 1; /* Allow the border to expand and fill available space */
    width: 100%; /* Ensure it takes the remaining width */
}

/* Style for the pseudo-element used to create the animated border */
.animate-border:after {
    animation: zumpX 40s linear infinite;
    background-color: #44a05b;
    border-radius: 50%;
    content: "";
    height: 7px;
    position: absolute;
    top: -3px; /* Adjust position as needed */
    left: 0;
    width: 7px;
}

/* Keyframes for animation */
@keyframes zumpX {
    0%, 100% {
        left: 0;
    }
    
    50% {
        left: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Titleholder/Title.css"],"names":[],"mappings":"AAAA,uDAAuD;AACvD;IACI,aAAa,EAAE,yCAAyC;IACxD,mBAAmB,EAAE,4BAA4B;IACjD,SAAS,EAAE,2CAA2C;IACtD,aAAa;IACb,WAAW,EAAE,kCAAkC;IAC/C,sBAAsB,EAAE,6CAA6C;AACzE;;AAEA,uBAAuB;AACvB;IACI,eAAe;IACf,YAAY;IACZ,SAAS;IACT,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA,kCAAkC;AAClC;IACI,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,YAAY,EAAE,wDAAwD;IACtE,WAAW,EAAE,wCAAwC;AACzD;;AAEA,oEAAoE;AACpE;IACI,oCAAoC;IACpC,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,SAAS,EAAE,8BAA8B;IACzC,OAAO;IACP,UAAU;AACd;;AAEA,4BAA4B;AAC5B;IACI;QACI,OAAO;IACX;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":["/* Style for the title holder to align items in a row */\n.title-holder {\n    display: flex; /* Use flexbox for horizontal alignment */\n    align-items: center; /* Center items vertically */\n    gap: 10px; /* Space between text and animated border */\n    padding: 20px;\n    width: 100%; /* Ensure it takes up full width */\n    box-sizing: border-box; /* Include padding in element's total width */\n}\n\n/* Style for the text */\n.text {\n    font-size: 2rem;\n    color: green;\n    margin: 0;\n    white-space: nowrap;\n    font-weight: 700;\n}\n\n/* Style for the animated border */\n.animate-border {\n    background-color: #ddd;\n    height: 1px;\n    position: relative;\n    flex-grow: 1; /* Allow the border to expand and fill available space */\n    width: 100%; /* Ensure it takes the remaining width */\n}\n\n/* Style for the pseudo-element used to create the animated border */\n.animate-border:after {\n    animation: zumpX 40s linear infinite;\n    background-color: #44a05b;\n    border-radius: 50%;\n    content: \"\";\n    height: 7px;\n    position: absolute;\n    top: -3px; /* Adjust position as needed */\n    left: 0;\n    width: 7px;\n}\n\n/* Keyframes for animation */\n@keyframes zumpX {\n    0%, 100% {\n        left: 0;\n    }\n    \n    50% {\n        left: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
