// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editorial-head i {
    font-size: 50px;
    margin-top: 25px;
    color: #32757e;
    text-shadow: 2px 2px 2px #a77f06;
        width: 120px;
        display: flex;
        margin:auto;
    
}
.editorial-card h1 {
    color: #32757e !important;
    text-shadow: 2px 2px 2px #a77f06 !important;
    font-weight: 800;
}
@media(max-width:600px){
    .editorial-card h1{
        font-size:20px;
    }
    .editorial-head i{
        padding-left:35%;
        margin:0;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Titletag/Titletag.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gCAAgC;QAC5B,YAAY;QACZ,aAAa;QACb,WAAW;;AAEnB;AACA;IACI,yBAAyB;IACzB,2CAA2C;IAC3C,gBAAgB;AACpB;AACA;IACI;QACI,cAAc;IAClB;IACA;QACI,gBAAgB;QAChB,QAAQ;IACZ;AACJ","sourcesContent":[".editorial-head i {\n    font-size: 50px;\n    margin-top: 25px;\n    color: #32757e;\n    text-shadow: 2px 2px 2px #a77f06;\n        width: 120px;\n        display: flex;\n        margin:auto;\n    \n}\n.editorial-card h1 {\n    color: #32757e !important;\n    text-shadow: 2px 2px 2px #a77f06 !important;\n    font-weight: 800;\n}\n@media(max-width:600px){\n    .editorial-card h1{\n        font-size:20px;\n    }\n    .editorial-head i{\n        padding-left:35%;\n        margin:0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
