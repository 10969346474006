import React from 'react'
import './Downborder.css'
const Downborder = () => {
  return (
    <div>
        <div class="downborder">
            <img src='https://ecroptoday.azureedge.net/ecroptoday/Border.png' alt="downborder"/>
        </div>
    </div>
  )
}

export default Downborder