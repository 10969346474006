// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.in-div {
    background: #336e3c;
    border: 2px solid #f5eb2f;
    border-radius: 105px 0px 105px 0px;
}
.in-div .jj-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: start;
}
.in-div i {
    border: 2px solid #f5eb2f;
    height: 82px;
    width: 82px;
    border-radius: 50%;
    margin-right: 20px;
    font-size: 30px;
    line-height: 82px;
    text-align: center;
    color: #f5eb2f;
}
.in-div h5{
    text-align: center;
}
.in-div h4{
    color:#ffffff;
}
.hhh {
    background: #ddddd9;
    color: #d60c0c;
    text-align: center;
}
@media(max-width:600px){
    .guidediv {
       width:100%;
       padding:0 10px;
    }
    .guidediv ul h5 {
        font-size: 25px;}
       
}`, "",{"version":3,"sources":["webpack://./src/Components/Guideline/Greencard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,kCAAkC;AACtC;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,cAAc;IACd,kBAAkB;AACtB;AACA;IACI;OACG,UAAU;OACV,cAAc;IACjB;IACA;QACI,eAAe,CAAC;;AAExB","sourcesContent":[".in-div {\n    background: #336e3c;\n    border: 2px solid #f5eb2f;\n    border-radius: 105px 0px 105px 0px;\n}\n.in-div .jj-info {\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    text-align: start;\n}\n.in-div i {\n    border: 2px solid #f5eb2f;\n    height: 82px;\n    width: 82px;\n    border-radius: 50%;\n    margin-right: 20px;\n    font-size: 30px;\n    line-height: 82px;\n    text-align: center;\n    color: #f5eb2f;\n}\n.in-div h5{\n    text-align: center;\n}\n.in-div h4{\n    color:#ffffff;\n}\n.hhh {\n    background: #ddddd9;\n    color: #d60c0c;\n    text-align: center;\n}\n@media(max-width:600px){\n    .guidediv {\n       width:100%;\n       padding:0 10px;\n    }\n    .guidediv ul h5 {\n        font-size: 25px;}\n       \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
