// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-outer .text1{
    font-size:25px;
}
.sidebar-div{
    background-color: #fff;
}
.sidebar-outer a{
    color:black;
}
.sidebar-outer a:hover{
    color:orange;
}
.sidebar-outer span{
    color:orange;
}
.sidebar-outer i{
    color:rgba(128, 128, 128, 0.87);
}
.search-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 10px auto;
}
.search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}
.search-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.search-button img {
    width: 20px;
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,+BAA+B;AACnC;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,eAAe;AACnB;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".sidebar-outer .text1{\n    font-size:25px;\n}\n.sidebar-div{\n    background-color: #fff;\n}\n.sidebar-outer a{\n    color:black;\n}\n.sidebar-outer a:hover{\n    color:orange;\n}\n.sidebar-outer span{\n    color:orange;\n}\n.sidebar-outer i{\n    color:rgba(128, 128, 128, 0.87);\n}\n.search-container {\n    position: relative;\n    width: 100%;\n    max-width: 400px;\n    margin: 10px auto;\n}\n.search-input {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    outline: none;\n    font-size: 16px;\n}\n.search-button {\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: 10px;\n    border: none;\n    background-color: transparent;\n    cursor: pointer;\n}\n.search-button img {\n    width: 20px;\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
