// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contwidth{
    width:70%;
    margin:auto
}
.contwidth h2 {
    color: #2b9240;
    margin-top: 60px;
    text-decoration: underline;
}

.formwidth{
    width:70%;
    margin:auto;
    background-color: #3d923b21;
    margin-top: 30px;
}
@media(max-width:600px){
    .contwidth,.formwidth {
        width:100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Submission/Submitarticle.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT;AACJ;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,SAAS;IACT,WAAW;IACX,2BAA2B;IAC3B,gBAAgB;AACpB;AACA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".contwidth{\n    width:70%;\n    margin:auto\n}\n.contwidth h2 {\n    color: #2b9240;\n    margin-top: 60px;\n    text-decoration: underline;\n}\n\n.formwidth{\n    width:70%;\n    margin:auto;\n    background-color: #3d923b21;\n    margin-top: 30px;\n}\n@media(max-width:600px){\n    .contwidth,.formwidth {\n        width:100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
