// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-caption{
    background: #0006;}
    .carousel-caption h5,.carousel-caption p{
        color: orange;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
    }
    .carousel-caption i{
        color: white;
    }
    .h-zoom{
        transition: transform 0.3s ease;
    }
    .h-zoom:hover{
        transform: scale(1.05);
    }
    .carousel-inner1 {
        overflow: hidden;
        height: 245px;
    }
    .carousel-inner1 img{
        width:100%;
    }
    @media(max-width:768px){
        .hh{
            margin-top:40px;
        }
        .hh .col-md-12{
            margin-top:20px;
        }
    }
    @media(max-width:600px){
        .carousel-inner1 {
            height: auto;
        }
        .text {
            font-size: 20px!important;
        }
        .h-sec-img{
            margin-top:20px;
        }        
    }`, "",{"version":3,"sources":["webpack://./src/Components/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB,CAAC;IAClB;QACI,aAAa;QACb,yBAAyB;QACzB,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,YAAY;IAChB;IACA;QACI,+BAA+B;IACnC;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,gBAAgB;QAChB,aAAa;IACjB;IACA;QACI,UAAU;IACd;IACA;QACI;YACI,eAAe;QACnB;QACA;YACI,eAAe;QACnB;IACJ;IACA;QACI;YACI,YAAY;QAChB;QACA;YACI,yBAAyB;QAC7B;QACA;YACI,eAAe;QACnB;IACJ","sourcesContent":[".carousel-caption{\n    background: #0006;}\n    .carousel-caption h5,.carousel-caption p{\n        color: orange;\n        text-transform: uppercase;\n        font-size: 18px;\n        font-weight: 600;\n    }\n    .carousel-caption i{\n        color: white;\n    }\n    .h-zoom{\n        transition: transform 0.3s ease;\n    }\n    .h-zoom:hover{\n        transform: scale(1.05);\n    }\n    .carousel-inner1 {\n        overflow: hidden;\n        height: 245px;\n    }\n    .carousel-inner1 img{\n        width:100%;\n    }\n    @media(max-width:768px){\n        .hh{\n            margin-top:40px;\n        }\n        .hh .col-md-12{\n            margin-top:20px;\n        }\n    }\n    @media(max-width:600px){\n        .carousel-inner1 {\n            height: auto;\n        }\n        .text {\n            font-size: 20px!important;\n        }\n        .h-sec-img{\n            margin-top:20px;\n        }        \n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
